export const managementTeam = [
  {
    id: 1,
    name: "dr. setor quashigah",
    title: "Chief Executive Officer",
    image: "/images/5.png",
    appointment:
      "Dr Setor Quashigah was appointed as the first Chief Executive Officer of SCWMLC on 1st July, 2022.",
    career:
      "Setor is an experienced banker with over 20 years’ experience and a leadership coach with a reputation for developing superior talent. She has held a number of senior roles including Head of Direct Banking, Head of Proximity and Remote Banking and General Manager for Preferred and Priority Banking in Standard Chartered Bank Ghana PLC.",
    education:
      "Setor holds a Doctorate in Business Leadership from Swiss Business School, an MBA from the University of Leicester, UK, and a B.A. from the Kwame Nkrumah University of Science and Technology. She is also a certified Wealth Manager from INSEAD, France.",
  },

  {
    id: 2,
    name: "Jerry Asangba",
    title: "Chief Investment Officer",
    image: "/images/10.jpg",
    appointment:
      "Jerry Asangba was appointed as the Chief Investment Officer of SCWMLC on 1st July, 2022.",
    career:
      "Jerry Asangba has many years of banking experience spanning Relationship Management, Investment Advisory, Managed Investments and Wealth Management Propositions. He joined Standard Chartered in 2012 and is currently responsible for the development and implementation of product strategies and solutions aimed at supporting the Wealth and Retail Banking clients of the bank, through the  Standard Chartered Wealth Management Limited Company subsidiary.",
    education:
      "Jerry has master’s degree in Finance from University of Ghana Business School and a Bachelor of Science (BSc) with First Class honours in Accounting from Ghana Institute of Management and Public Administration (GIMPA). He is an Associate of the Chartered Institute for Securities and Investments, CISI (UK).",
  },

  {
    id: 3,
    name: "Marilyn Kwasie Esq.",
    title: "Compliance Officer",
    image: "/images/7.png",
    appointment:
      "Marilyn was appointed as SCWMLC Compliance Officer on 11th February 2023.",
    career:
      "Prior to joining Compliance she had over thirteen (13) years of banking experience with Standard Chartered Bank where she held multiple roles including Personal Financial Consultant, and Senior Priority Banking Relationship Manager within the Retail Banking and Wealth Management Business. Marilyn is a Lawyer and has been a member of the Ghana Bar Association for the past seven (7) years. ",
    education:
      "Marilyn is an Associate of the Chartered Institute for Securities and Investments (CISI UK). She holds a Bachelor of Laws (LLB) from the University of London, a Qualifying Law Certificate from the Ghana School of Law and a Bachelor of Science (BSc) in Mathematics from the Kwame Nkrumah University of Science and Technology.",
  },
];

export const managementTeam2 = [
  {
    id: 3,
    name: "kwabena koranteng",
    title: "Board Chairman",
    image: "/images/new-1.png",
    appointment:
      "Kwabena was appointed as the First Board Chairman of SCWMLC on 12th October 2022. He has over 22 years’ experience in the financial sector having held roles at Standard Chartered Bank Ghana and Barclays Group across several countries in Africa and Indian Ocean.",
    career:
      "Kwabena is currently the West Africa Cluster Chief Risk Officer for Standard Chartered Bank. He previously held roles including Chief Risk Officer Ghana, Chief Risk Officer Seychelles, Regional Credit Officer Johannesburg, Head of Wholesale Credit, Ghana and Head of Compliance, Seychelles. Kwabena is passionate about developing talent and empowering young people.",
    education:
      "He is Chartered Management Account and a member of the Institute of Chartered Accountants Ghana. He holds a Master of Business Administration from the University of Leicester U.K, Master of Law (Corporate & Commercial Law) from University of Ghana and a first degree in BSC Business Administration (Banking and Finance) from the University of Ghana.",
  },
  {
    id: 1,
    name: " dr. setor quashigah",
    title: "Executive Director",
    image: "/images/5.png",
    appointment:
      "Dr Setor Quashigah was appointed as the First Chief Executive Officer of SCWMLC on 12th October 2022.",
    career:
      "Setor is an experienced banker with over 20 years’ experience and a leadership coach with a reputation for developing superior talent. She has held a number of senior roles including Head of Direct Banking, Head of Proximity and Remote Banking and General Manager for Preferred and Priority Banking in Standard Chartered Bank Ghana PLC.",
    education:
      "Setor holds a Doctorate in Business Leadership from Swiss Business School, an MBA from the University of Leicester, UK, and a B.A. from the Kwame Nkrumah University of Science and Technology. She is also a certified Wealth Manager from INSEAD, France.",
  },

  {
    id: 2,
    name: " albert larweh asante",
    title: "Non-Executive Director",
    image: "/images/2.png",
    appointment:
      "Albert was appointed as a Non-Executive Director of Standard Chartered Wealth Management Limited Company on 21st July 2023 and brings aboard considerable experience in Strategy, Financial Planning and Analysis, Leadership and Enterprise Risk Management.",
    career:
      "He is currently the Chief Financial Officer, and an Executive Director of Standard Chartered Bank Ghana PLC. Prior to his current role, Albert served as Cluster Head of Finance (West Africa) for Corporate and Investment Banking and also had stints as an Executive Director, Chief Financial Officer, Financial Controller for Standard Chartered Bank Angola S.A, and acted as CEO for nearly a year. He is also a trustee of Standard Chartered Science Education and Kenneth Dadzie Memorial Education Endowment Funds.",
    education:
      "Albert is a Fellow of the Institute of Chartered Accountants, Ghana; a CFA Charterholder, a member of the Ghana Association of Restructuring and Insolvency Advisors (GARIA). He holds an MBA from the University of Warwick, United Kingdom, and a Bachelor of Science in Administration (Accounting option) degree from the University of Ghana, Legon.",
  },

  {
    id: 4,
    name: "joseph boakye danquah ampofo",
    title: "Independent Non-Executive Director",
    image: "/images/3.png",
    appointment:
      "Joseph joined the Board of SCWMLC on 12 October 2023 as an Independent Non-Executive Director with a strong dedication to delivering excellent client service, which has been a cornerstone in his career.",
    career:
      "Joseph is the Chief Executive Officer of Enterprise Trustees Limited with over 25 years’ experience with various leadership roles within the Insurance sector. He commenced his career with Enterprise Insurance Limited and held various roles prior to joining Enterprise Trustees Limited as the Head of Sales & Marketing. Prior to his appointment as the CEO, he was the General Manager and subsequently appointed as an Executive Director.",
    education:
      "He holds a Bachelor of Arts in Political Science and Sociology from University of Ghana Legon In addition, he holds a Diploma in Insurance from the West Africa Insurance Institute (WAII), The Gambia; an Advanced Diploma from the Ghana Insurance College, a Cert CII Holder of the Chartered Insurance Institute, UK and an SMDP Certificate from University of Stellenbosch Executive Program, South Africa. With a passion for youth empowerment and leadership, Joseph is a fellow of the Africa Leadership Initiative – West Africa and the Aspen Global Leadership Network. Joseph is the President of the Chamber of Corporate Trustees in Ghana, Vice President of the University of Ghana Alumni Association and a Director at An Art of Giving. He is an avid golfer.",
  },
];

export const managementTeam3 = [
  {
    id: 1,
    name: "kobina sam bentsi-enchill",
    title: "Independent Non- Executive Director",
    image: "/images/4.png",
    appointment:
      "Kobina joined the Board of SCWMLC on 12 October 2023 as an Independent Non-Executive Director and brings on board rich experience in Financial management and Reporting. He is currently the Chief Finance Officer of Lonestar Cell Corporation (MTN Liberia).",
    career:
      "Kobina has over 23 years’ experience in Accounting and Finance and has worked across industries including Insurance, Commerce and Telecommunications over those years. Over the course of his career, Kobina has occupied various roles in Internal Audit, Treasury & Cash Management, Branch Operations, Finance Operations, Corporate Performance Management, Supply Chain Management and Financial Planning and Analysis.",
    education:
      "Kobina holds a Bachelor of Commerce from the University of Cape Coast and is a qualified member of the Institute of Chartered Accountants, Ghana (ICAG), the Chartered Institute of Management Accountants (CIMA, UK) and Institute of Directors, Ghana (IOD).",
  },

  {
    id: 2,
    name: "Yaw Amponsah Esq.",
    title: "Company Secretary",
    image: "/images/8.png",
    appointment:
      "Yaw was appointed as Company Secretary on 12th October 2022 and is currently a Legal Counsel in the Legal Department of Standard Chartered Bank Ghana PLC.",
    career:
      "He is a lawyer of fifteen (15) years standing and a Chartered Arbitrator. He has a diverse experience as a legal practitioner having worked as a Civil Defense Lawyer at the Attorney General’s Department, an in-house lawyer at PBC Limited, a licensed cocoa buying company and at Ghana’s premier university, the University of Ghana.",
    education:
      "Yaw holds a Bachelor of Arts in Law with Marketing from Buckinghamshire New University, United Kingdom and a Postgraduate Diploma in International Commercial Arbitration from Queen Mary, University of London.",
    link: "ppp",
  },
];

export const InvestmentAdvisors = [
  {
    id: 1,
    name: "Joshua Aryeequaye",
    title: "Chief Executive Officer",
    image: "/images/Joshua_Aryeequaye.png",
  },
  {
    id: 2,
    name: "Nelson Kpatakpa",
    title: "Chief Executive Officer",
    image: "/images/Nelson_Kpatakpa.png",
  },
  {
    id: 3,
    name: "Jeremy Yeboah Boateng",
    title: "Chief Executive Officer",
    image: "/images/Jeremy_Yeboah_Boateng.png",
  },
  {
    id: 4,
    name: "Pamela Elinam Oyeh",
    title: "Chief Executive Officer",
    image: "/images/Pamela_Elinam_Oyeh-WM.png",
  },
];

export const accordionData = [
  {
    id: 1,
    title: "Mutual Funds",
    image: "/images/grid4.png",
    content:
      "Our mutual funds offer an affordable access to a range of assets and markets to match your risk profile and financial goals.",
    link: "/services#mutual-funds",
  },
  // {
  //   id: 1,
  //   title: "Bancassrance - Protecting You and Your valuables",
  //   image: "/images/product4.png",
  //   content:
  //     "Take advantage of our partnerships with global insurance providers to find the right protection for your health, retirement, education, legacy planning, savings, and investments.",
  //   link: "/services#bancassrance",
  // },
  {
    id: 1,
    title: "Wealth Lending",
    image: "/images/grid3.png",
    content: "Unlocking Your Cash Needs with Foreign Currency Overdrafts",
    link: "/services#wealth-lending",
  },
];

export const accordionData2 = [{ link: "/services#mutual-funds" }];
