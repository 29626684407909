import React from "react";

const BoardHero = () => {
  return (
    <section>
      <header className="about_bg flex justify-end flex-col padding">
        <section className="md:mb-[40px] mb-[20px]">
          <div className="sm:text-[32px] text-[25px] boldF text-white sm:leading-[43px] leading-[25px]">
            <div className="flex items-center">
              <img
                src="/images/blueEllipse.svg"
                alt="ellipse"
                className="xs:ml-[-25px] ml-[-15px] xs:mr-[10px] mr-[6px]"
              />
              We are here
              <img
                src="/images/greenEllipse.svg"
                alt="ellipse"
                className="mt-[10px]  ml-[15px]"
              />
            </div>
          </div>
        </section>
      </header>
    </section>
  );
};

export default BoardHero;
