import React from "react";
import Navbar from "../components/Navbar";
import BoardHero from "../components/BoardHero";
import InvestC from "../components/InvestC";
import Footer from "../components/Footer";

const InvestmentAdvisors = () => {
  return (
    <section>
      <Navbar />
      <BoardHero />
      <InvestC />
      <Footer />
    </section>
  );
};

export default InvestmentAdvisors;
