import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BoardHero from "../components/BoardHero";
import TeamB from "../components/TeamB";
import TeamC from "../components/TeamC";

const BoardofDirectors = () => {
  return (
    <section>
      <Navbar />
      <BoardHero />
      <TeamB />
      <TeamC />
      <Footer />
    </section>
  );
};

export default BoardofDirectors;
