import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import KeyHero from "../components/KeyHero";
import Team from "../components/Team";

const KeyPeople = () => {
  return (
    <div>
      <Navbar />
      <KeyHero />
      <Team />
      <Footer />
    </div>
  );
};

export default KeyPeople;
