import { useState } from "react";
import { managementTeam2 } from "../constants";
import { Link } from "react-router-dom";

const TeamB = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="padding">
      <div className="h-full md:w-[60%] w-full">
        <div className={`xs:mt-[20px] mt-[6px]`}>
          <Link
            to={"/about"}
            className="flex  items-center xs:mt-[16px] mt-[8px] xs:mb-[20px] mb-[20px]"
          >
            <img
              src="/images/arrow-left.svg"
              alt="arrow_blue"
              className="w-[20px] h-[20px] mb-[-5px] "
            />
            <p className="mediumF text-lightBlue text-[16px] underline hover:underline-darkGray cursor-pointer">
              About us
            </p>
          </Link>
        </div>
      </div>

      {/* Management */}
      <h1 className="text-center blue_gradient_text mt-[41px] md:text-[32px] sm:text-[25px] text-[20px] boldF leading-[38px]">
        Meet our Board of Directors
      </h1>

      <section className="flex items-center  xs:mb-[20px] mb-[10px]">
        <h2 className="sm:text-[20px] text-[18px] sm:w-[220px] w-full boldF text-darkGray leading-[24px]">
          Board of Directors
        </h2>
        <div className="bg-[#DCDDDD] w-[80%] h-[1px] md:block hidden mt-[4px]"></div>
      </section>
      <section className="grid md:grid-cols-4 grid-cols-1 gap-[10px] relative md:text-start text-center">
        {managementTeam2.map((item, index) => (
          <div key={index}>
            <div
              className={` w-full flex flex-col items-center justify-center text-center 
                `}
            >
              <div className="flex items-center flex-col lg:h-[250px] md:h-[250px] h-full">
                <img
                  src={item.image}
                  alt="team_image"
                  className="w-[220px] h-[250px] object-cover"
                />
                <h2 className="text-darkGray mediumF xs:text-[18px] text-[16px] leading-[21px] capitalize">
                  {item.name}
                </h2>
                <p className="xs:text-[14px] text-[13px] mediumF text-deepGray leading-[16px] capitalize mt-[5px]">
                  {item.title}
                </p>
              </div>
              {activeIndex !== index ? (
                <div
                  className={`flex items-center  cursor-pointer lgg:mt-[40px] md:mt-[70px] mt-[10px] xs:mb-[20px]  ${
                    activeIndex === index && "hidden"
                  }`}
                  onClick={() => toggleAccordion(index)}
                >
                  <p className="lightF text-lightBlue xs:text-[16px] text-[14px] cursor-pointer hover:underline transition">
                    View more
                  </p>
                  <img
                    src="/images/arrowdownblue.svg"
                    alt="arrow_blue"
                    className="w-[20px] h-[20px] mb-[-5px]"
                  />
                </div>
              ) : (
                <img
                  src="/images/arrow-view.svg"
                  alt="arrow-view "
                  className="md:mt-[60px] mt-[22px] md:flex hidden"
                />
              )}

              <div className="md:hidden mt-[10px] text-start">
                <section className="mt-[10px] ">
                  {activeIndex === index && (
                    <div className="mt-[-10px] bg-[#F7F7F7] px-[16px] py-[15px]">
                      <p className="sm:text-[18px] xs:text-[16px] text-[15px] lightF text-deepGray">
                        <span className="text-darkGray boldF leading-[21px]">
                          Appointed:{" "}
                        </span>
                        {item.appointment}
                      </p>

                      <p className="sm:text-[18px]  xs:text-[16px] text-[15px] lightF text-deepGray mt-[10px]">
                        <span className="text-darkGray boldF leading-[21px]">
                          Career:{" "}
                        </span>
                        {item.career}
                      </p>

                      <p className="sm:text-[18px]  xs:text-[16px] text-[15px] lightF text-deepGray mt-[10px]">
                        <span className="text-darkGray boldF leading-[21px]">
                          Education:{" "}
                        </span>
                        {item.education}
                      </p>

                      <div
                        className="flex items-center justify-end cursor-pointer mt-[5px] border-t border-[#DCDDDD]"
                        onClick={() => toggleAccordion(index)}
                      >
                        <p className="lightF text-lightBlue xs:text-[16px] text-[14px] cursor-pointer hover:underline transition text-end">
                          Hide Details
                        </p>
                        <img
                          src="/images/arrowdownblue.svg"
                          alt="arrow_blue"
                          className="w-[20px] h-[20px] mb-[-5px]"
                        />
                      </div>
                    </div>
                  )}
                </section>
              </div>
            </div>
          </div>
        ))}
      </section>

      {managementTeam2.map((item, index) => (
        <section className="md:pt-[5px] lg:mt-[-5px] mt-[-10px] pt-[10px] hidden md:flex">
          {activeIndex === index && (
            <div className="mt-[-10px] bg-[#F7F7F7] px-[16px] py-[15px]">
              <p className="sm:text-[18px] text-[16px] lightF text-deepGray">
                <span className="text-darkGray boldF leading-[21px]">
                  Appointed:{" "}
                </span>
                {item.appointment}
              </p>

              <p className="sm:text-[18px] text-[16px] lightF text-deepGray mt-[10px]">
                <span className="text-darkGray boldF leading-[21px]">
                  Career:{" "}
                </span>
                {item.career}
              </p>

              <p className="sm:text-[18px] text-[16px] lightF text-deepGray mt-[10px]">
                <span className="text-darkGray boldF leading-[21px]">
                  Education:{" "}
                </span>
                {item.education}
              </p>

              <div
                className="flex items-center justify-end cursor-pointer mt-[5px] border-t border-[#DCDDDD]"
                onClick={() => toggleAccordion(index)}
              >
                <p className="lightF text-lightBlue text-[16px] cursor-pointer hover:underline transition  text-end">
                  Hide Details
                </p>
                <img
                  src="/images/arrowdownblue.svg"
                  alt="arrow_blue"
                  className="w-[20px] h-[20px] mb-[-5px]"
                />
              </div>
            </div>
          )}
        </section>
      ))}
    </section>
  );
};

export default TeamB;
