import React from "react";
import Button from "./Button";
import { Link, useNavigate } from "react-router-dom";

const Explore2 = () => {
  const navigate = useNavigate();
  return (
    <section className=" grid smm:grid-cols-2 lg:gap-[4rem] md:gap-[2rem] gap-[1rem] items-center justify-center padding bg-[#F7FBFF] pb-[2rem]">
      <img
        src="/images/world.png"
        alt="explore_image"
        className="lg:w-[652px] lg:h-[474px] md:w-[400px] smm:w-[300px] md:h-[500px] sm:h-[400px] w-full h-full object-cover rounded-[20px]"
      />
      <div>
        <h1 className="blue_gradient_text md:text-[32px] sm:text-[25px] text-[20px] boldF md:leading-[38px] leading-[28px]">
          Market views on-the-go
        </h1>
        <p
          className="md:mt-[30px] mt-[15px] text-deepGray 
        md:text-[16px]  text-[14px] lightF leading-[22px] max-w-[420px]"
        >
          In 2024, interest rates may drop, benefiting investments. Standard
          Chartered advises diversified portfolios through offshore mutual funds
          and bonds, offering monthly dividends and attractive interest payments
          in various currencies.
        </p>
        <div className="md:mt-[56px] mt-[20px]">
          <Link
            to="https://www.sc.com/gh/market-outlook/?intcid=web&lang=en&ctry=GH&seg=GWM&channel=PLRB"
            target="_blank"
          >
            <Button text={"Read more"} icon={"/images/arrow-button.svg"} />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Explore2;
