import React from "react";

const FundsData = () => {
  return <div></div>;
  // see aditional products here
  // make the view more lined up
  // the space of the whole test
};

export default FundsData;
